import {
  FETCH_COMMENTS,
  UPDATE_COMMENT,
  SET_PAGINATIONINFO
} from "../actions/type";

const INIT_STATE = {
  commentMap: {},
  totalComments: 0, //預設 0
  pagination: {
    page: 0, // 預設為第 1 頁
    rowsPerPage: 10 // 預設一頁 10 筆
  }
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_COMMENTS:
      return {
        ...state,
        commentMap: { ...payload.commentMap },
        totalComments: payload.totalComments
      };

    case UPDATE_COMMENT:
      return {
        ...state,
        commentMap: {
          ...state.commentMap,
          // [payload.comment._page]: payload.comment,
          [payload.comment._id]: payload.comment
        }
      };

    case SET_PAGINATIONINFO:
      return {
        ...state,
        pagination: payload // 更新 pagination 狀態
      };

    default:
      return state;
  }
};
