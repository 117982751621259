import axios from "axios";

import { FETCH_COMMENTS, UPDATE_COMMENT, SET_PAGINATIONINFO } from "./type";

//fetch section's comments
export const fetchComments =
  (fromProps, callback) => async (dispatch, getStore) => {
    let role = fromProps.role;
    let userId = fromProps.userId;
    let sectionId = fromProps.sectionId;

    const {
      data: { commentMap }
    } = await axios.get(`comments/${sectionId}`);

    dispatch({ type: FETCH_COMMENTS, payload: { commentMap } });

    if (callback) callback();
  };

export const fetchAllComments =
  (fromProps, callback) => async (dispatch, getStore) => {
    const { pagination } = getStore().comment;

    let page = pagination.page;
    let limit = pagination.rowsPerPage;

    const {
      data: { commentMap, totalComments }
    } = await axios.get(`comments?page=${page}&limit=${limit}`);

    dispatch({
      type: FETCH_COMMENTS,
      payload: { commentMap, totalComments }
    });

    if (callback) callback();
  };

// update(create) comment
export const updateTeacherComment =
  (fromProps, callback) => async (dispatch, getStore) => {
    let teacherId = fromProps.teacherId;

    const {
      data: { comment }
    } = await axios.patch(`comments/${teacherId}`, {
      patch: {
        data: fromProps
      }
    });

    dispatch({ type: UPDATE_COMMENT, payload: { comment } });

    if (callback) callback();
  };

//edit comment resolved
export const editIsNeedResolved =
  (fromProps, callback) => async (dispatch, getStore) => {
    let commentId = fromProps.commentId;

    const {
      data: { comment }
    } = await axios.patch(`resolved/${commentId}`, {
      patch: {
        data: fromProps
      }
    });

    dispatch({ type: UPDATE_COMMENT, payload: { comment } });

    if (callback) callback();
  };

//del commment
export const deleteComment =
  (fromProps, callback) => async (dispatch, getStore) => {
    let commentId = fromProps.commentId;

    const { pagination } = getStore().comment;

    let page = pagination.page;
    let limit = pagination.rowsPerPage;

    try {
      // **1. 先刪除後端留言**
      await axios.delete(`comment/${commentId}`);

      // **2. 重新取得當前分頁的留言**
      const {
        data: { commentMap, totalComments }
      } = await axios.get(`comments?page=${page}&limit=${limit}`);

      // **3. 更新 Redux 狀態**
      dispatch({
        type: FETCH_COMMENTS,
        payload: { commentMap, totalComments }
      });

      //TODO:刪除最後一筆資料後，總頁數減少，但目前仍停留在已不存在的頁數

      if (callback) callback();
    } catch (error) {
      console.error("刪除留言失敗", error);
    }
  };

export const setPaginationInfo =
  (fromProps, callback) => (dispatch, getStore) => {
    let page = fromProps.newPage;
    let rowsPerPage = fromProps.rowsPerPage;

    dispatch({
      type: SET_PAGINATIONINFO,
      payload: { page, rowsPerPage }
    });

    // 重新 fetch 新的評論
    dispatch(fetchAllComments({ page, rowsPerPage }));

    if (callback) callback();
  };
